import React, { useEffect, useRef } from 'react';
import { reduxForm } from 'redux-form';
import moment from 'moment';

import rackList from 'services/rackList';
import zones from 'services/zones';
import { ParkingFilter, DateSelect, ZoneInput } from 'libs/form';
import { Container, Input, Multiselect, DoubleField, DateTime, Toggle, ButtonsContainer, DictFilter } from 'libs/form';
import { ResetBtn, UseBtn } from 'libs/ui';

const initialValues = {
  datetime_from: moment().startOf('day').format(),
  datetime_to: moment().endOf('day').format(),
};

const Filter = ({ handleSubmit, reset, change, setInitial, locationState }) => {
  useEffect(() => {
    setInitial(initialValues);
  }, [setInitial]);

  /**
   * set filter
   * @param {*} field
   * @param {*} value
   */
  const setFilter = (field, value) => {
    change(field, value);
  };

  /**
   * update filter
   * @param {*} filter
   */
  const updateFilter = (filter) => {
    reset();
    for (let field in filter) {
      setFilter(field, filter[field]);
    }
  };

  // did mount
  useEffect(() => {
    if (locationState && locationState.filter) {
      updateFilter(locationState.filter);
    }
  }, []);

  return (
    <Container>
      <form onSubmit={handleSubmit}>
        <DateSelect from_name="datetime_from" to_name="datetime_to" change={change}>
          <DoubleField>
            <DateTime width="160px" name="datetime_from" type="dateTime" label="Дата и время" />
            <DateTime width="160px" name="datetime_to" type="dateTime" />
          </DoubleField>
        </DateSelect>

        <DictFilter w={150} dict="passage.result" />
        <DictFilter w={150} dict="passage.type" />

        <Multiselect
          width="150px"
          name="zones_from"
          type="multiselect"
          label="Зона из"
          additionalComponent={ZoneInput}
          data={zones.getZones()}
          countDisplayedIcons={1}
        />

        <Multiselect width="180px" name="rack_ids" label="Узел/стойка" type="rackSelect" data={rackList.getItems()} />

        <Multiselect
          width="150px"
          name="zones_to"
          type="multiselect"
          label="Зона в"
          additionalComponent={ZoneInput}
          data={zones.getZones()}
          countDisplayedIcons={1}
        />

        <DictFilter w={150} dict="vehicle_class" />

        <Input width="150px" name="vehicle_plate" type="text" label="ГРЗ" placeholder="А000AA 000 rus" />
        <ParkingFilter />

        <DoubleField>
          <DictFilter w={160} dict="payment_mean_type" />
          <Input width="160px" name="mean_number" type="text" label="СО" placeholder="1234567890" />
        </DoubleField>

        <Input width="220px" name="csc_owner" type="text" label="Держатель" placeholder="Введите текст для поиска" />
        <Input width="220px" name="client_name" type="text" label="Клиент" placeholder="Введите текст для поиска" />

        <div>
          <p>&nbsp;</p>
          <Toggle label="Подозрительные проезды" name="is_suspicious" type="toggle" />
        </div>

        <ButtonsContainer>
          <ResetBtn onClick={reset} />
          <UseBtn />
        </ButtonsContainer>
      </form>
    </Container>
  );
};

export default reduxForm({
  form: 'passageFilter',
  destroyOnUnmount: false,
  initialValues: initialValues,
})(Filter);
